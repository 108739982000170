.contact-details-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: 100vh;
    background-color: #f5f5f5;
    margin-top: var(--dl-space-space-twounits);
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
}

@media screen and (max-width: 768px) {
    .contact-details-container{
        flex-direction: column;
        margin-top: 19rem;
    }
    .contact-details-left-container-title{
        padding-right: 0px;
        padding-left: 0px;
        padding-bottom: 0 !important;
    }
    .contact-details-right-container-title{
        /* border-top: 1px solid #ddd; */
        /* border-left: 0px solid #ddd; */
        padding-top: 0 !important;
        margin-top: 0 !important;
        padding-left: var(--dl-space-space-twounits) !important;
        padding-right: 0px;
    }
}

.contact-details-left-container-title{
    padding-right: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
}

.contact-details-left-container-title h1{
    font-size: 2.5rem;
    font-weight: 700;
    color: #000;
    /* margin-bottom: var(--dl-space-space-unit); */
}

.contact-subline{
    font-size: 1.1rem;
    font-weight: 400;
    color: #000;
    margin-bottom: var(--dl-space-space-twounits);
}

.contact-details-left-container-title h3{
    font-size: 1.2rem;
    /* font-weight: 400; */
    color: #000;
    margin-top: var(--dl-space-space-oneandhalfunits);
}

.contact-details-left-container-title-address p{
    font-size: 0.9rem;
    font-weight: 400;
    color: #000;
}

.contact-details-right-container-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    height: 100vh;
    background-color: #f5f5f5;
    margin-top: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-fourunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-twounits);
    border-left: 1px solid #ddd;
}

.contact-details-right-container-title div div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    /* height: 100vh; */
    background-color: #f5f5f5;
    margin-top: var(--dl-space-space-twounits);
    /* padding-top: var(--dl-space-space-twounits); */
    padding-bottom: var(--dl-space-space-twounits);
}

.contact-details-right-container-title div div input{
    width: 100%;
    height: 40px;
    border: 0.01px solid #ddd;
    border-radius: 5px;
    padding-left: 10px;
    margin-bottom: var(--dl-space-space-halfunit);
}

.contact-details-right-container-title div div textarea{
    width: 100%;
    height: 100px;
    border: 0.01px solid #ddd;
    border-radius: 5px;
    padding-left: 10px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
}

.contact-details-right-container-title div div button{
    /* width: 100%; */
    /* height: 40px; */
    border: 0.01px solid #ddd;
    /* border-radius: 8px; */
    /* padding-left: 10px; */
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    background-color: #000;
    color: #fff;
    font-weight: 700;
    font-size: 1rem;
    padding: 12px 36px;
    cursor: pointer;
    transition-duration: 150ms;
}

.contact-details-right-container-title div div button:hover{
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
}