.about-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.about-navbar {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 1000;
  position: fixed;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-pallet-green);
}
.about-header {
  position: relative;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
.about-navbar1 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
}
.about-container01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about-image {
  width: 24px;
  height: 24px;
  z-index: 100;
  margin-right: -32px;
}
.about-middle {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 1;
  border-color: transparent;
  justify-content: center;
}
.about-left {
  display: flex;
  grid-gap: 45px;
  position: relative;
  align-items: flex-start;
}
.about-text {
  text-decoration: none;
}
.about-text01 {
  text-decoration: none;
}
.about-text02 {
  text-decoration: none;
}
.about-logo-center {
  letter-spacing: 0.4em;
  text-decoration: none;
}
.about-right {
  display: flex;
  grid-gap: 45px;
  position: relative;
  align-items: flex-start;
}
.about-text05 {
  text-decoration: none;
}
.about-icons {
  display: flex;
}
.about-link {
  display: contents;
}
.about-icon {
  width: 24px;
  height: 24px;
  margin-right: 24px;
  text-decoration: none;
}
.about-link1 {
  display: contents;
}
.about-icon02 {
  width: 24px;
  height: 24px;
  margin-right: 24px;
  text-decoration: none;
}
.about-icon04 {
  width: 24px;
  height: 24px;
}
.about-burger-menu {
  display: none;
}
.about-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  display: none;
}
.about-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  padding: 32px;
  z-index: 100;
  position: absolute;
  transform: translateX(100%);
  transition: 0.3s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.about-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.about-container02 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.about-logo-center1 {
  color: var(--dl-color-gray-black);
  height: auto;
  font-size: 24px;
  font-family: Jost;
  font-weight: 500;
  line-height: 20px;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
  letter-spacing: 0.4em;
  text-decoration: none;
}
.about-close-mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.about-middle1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
}
.about-text07 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.about-text08 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.about-text09 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.about-text10 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.about-text11 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.about-text12 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.about-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.about-icon12 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.about-icon14 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.about-container03 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.about-banner {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  border-right: 2px #d4d4d4 dashed;
}
.about-text13 {
  font-size: 3rem;
  text-align: center;
}
.about-text14 {
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.about-banner1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  /* padding-top: 0; */
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.about-text19 {
  font-size: 3rem;
  text-align: center;
}
.about-text20 {
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.about-banner2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  justify-content: space-between;
  border-top-width: 1px;
}
.about-container04 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: space-between;
}
.about-text25 {
  text-align: center;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.about-text26 {
  margin-right: var(--dl-space-space-threeunits);
}
.about-container05 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.about-image1 {
  width: 437px;
  height: 300px;
  object-fit: cover;
  flex-shrink: 0;
}
.about-banner3 {
  width: 100%;
  display: flex;
  padding: 0px;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  background-image: url('https://bombaytiles.com/wp-content/uploads/2020/03/Different-types-of-flooring-tiles.jpg');
}
.about-container06 {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.7);
}
@media screen and (max-width: 479px) {
  .about-container06{
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
}
.about-text30 {
  color: #ffffff;
  font-size: 3rem;
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.about-text31 {
  color: rgb(255, 255, 255);
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-twounits);
}
.about-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.about-footer {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-950);
  border-width: 1px;
  flex-direction: column;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.about-footer1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: space-between;
}
.about-container08 {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
}
.about-text39 {
  margin-bottom: var(--dl-space-space-halfunit);
}
.about-text40 {
  color: var(--dl-color-gray-black50);
  margin-top: var(--dl-space-space-oneandhalfunits);
}
.about-text44 {
  color: var(--dl-color-gray-black50);
  margin-top: var(--dl-space-space-twounits);
}
.about-text45 {
  color: var(--dl-color-gray-black50);
  margin-top: 12px;
}
.about-links-container {
  display: flex;
  grid-gap: var(--dl-space-space-threeunits);
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.about-container09 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.about-text46 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.about-text47 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.about-text48 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.about-text49 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.about-text50 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.about-text51 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.about-text54 {
  color: var(--dl-color-gray-black50);
}
.about-container10 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.about-text55 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.about-text56 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.about-text57 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.about-text58 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.about-text59 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.about-container11 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.about-text60 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.about-text61 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
@media(max-width: 991px) {
  .about-container01 {
    display: none;
  }
  .about-left {
    display: none;
  }
  .about-right {
    display: none;
  }
  .about-icons {
    display: none;
  }
  .about-burger-menu {
    display: flex;
  }
  .about-icon06 {
    width: 24px;
    height: 24px;
    display: flex;
  }
  .about-logo-center1 {
    margin-left: 0px;
  }
  .about-text14 {
    max-width: 100%;
  }
  .about-text20 {
    max-width: 100%;
  }
  .about-banner2 {
    flex-direction: column;
  }
  .about-container04 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .about-text26 {
    text-align: center;
    margin-left: var(--dl-space-space-threeunits);
  }
  .about-container05 {
    justify-content: center;
  }
  .about-image1 {
    width: 100%;
    height: var(--dl-size-size-xxlarge);
  }
  .about-text31 {
    width: 100%;
  }
  .about-footer1 {
    flex-direction: column-reverse;
  }
  .about-links-container {
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .about-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about-container03 {
    flex-direction: column;
  }
  .about-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .about-text14 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .about-banner1 {
    margin-top: 0;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .about-text20 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .about-banner2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .about-text26 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .about-image1 {
    width: 100%;
  }
  .about-text31 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .about-footer1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .about-links-container {
    margin-top: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: space-between;
  }
}
@media(max-width: 479px) {
  .about-header {
    padding: var(--dl-space-space-unit);
  }
  .about-mobile-menu {
    padding: 16px;
  }
  .about-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .about-banner1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .about-banner2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .about-container05 {
    align-items: center;
    flex-direction: column;
  }
  .about-image1 {
    width: 100%;
    height: var(--dl-size-size-xlarge);
  }
  .about-container07 {
    align-items: center;
    flex-direction: column;
  }
  .about-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .about-links-container {
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }
  .about-container09 {
    margin-right: var(--dl-space-space-unit);
  }
  .about-container10 {
    margin-right: var(--dl-space-space-unit);
  }
}
