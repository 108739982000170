.products-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.products-navbar {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 1000;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-pallet-green);
}
.products-header {
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
.products-navbar1 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
}
.products-container1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.products-image {
  width: 24px;
  height: 24px;
  z-index: 100;
  margin-right: -32px;
}
.products-middle {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 1;
  border-color: transparent;
  justify-content: center;
}
.products-left {
  display: flex;
  grid-gap: 45px;
  position: relative;
  align-items: flex-start;
}
.products-text {
  text-decoration: none;
}
.products-text01 {
  text-decoration: none;
}
.products-text02 {
  text-decoration: none;
}
.products-logo-center {
  letter-spacing: 0.4em;
  text-decoration: none;
}
.products-right {
  display: flex;
  grid-gap: 45px;
  position: relative;
  align-items: flex-start;
}
.products-text05 {
  text-decoration: none;
}
.products-icons {
  display: flex;
}
.products-link {
  display: contents;
}
.products-icon {
  width: 24px;
  height: 24px;
  margin-right: 24px;
  text-decoration: none;
}
.products-link1 {
  display: contents;
}
.products-icon02 {
  width: 24px;
  height: 24px;
  margin-right: 24px;
  text-decoration: none;
}
.products-icon04 {
  width: 24px;
  height: 24px;
}
.products-burger-menu {
  display: none;
}
.products-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  display: none;
}
.products-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  padding: 32px;
  z-index: 100;
  position: absolute;
  transform: translateX(100%);
  transition: 0.3s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.products-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.products-container2 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.products-logo-center1 {
  color: var(--dl-color-gray-black);
  height: auto;
  font-size: 24px;
  font-family: Jost;
  font-weight: 500;
  line-height: 20px;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
  letter-spacing: 0.4em;
  text-decoration: none;
}
.products-close-mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.products-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.products-middle1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
}
.products-text07 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.products-text08 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.products-text09 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.products-text10 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.products-text11 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.products-text12 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.products-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.products-icon12 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.products-icon14 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.products-text13 {
  align-self: center;
  margin-top: var(--dl-space-space-fiveunits);
  margin-left: 0px;
  padding-top: var(--dl-space-space-unit);
}
.products-gallery {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-halfunit);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: flex-start;

}
.products-footer {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-950);
  border-width: 1px;
  flex-direction: column;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.products-footer1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: space-between;
}
.products-container3 {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
}
.products-text14 {
  margin-bottom: var(--dl-space-space-halfunit);
}
.products-text15 {
  color: var(--dl-color-gray-black50);
  margin-top: var(--dl-space-space-oneandhalfunits);
}
.products-text19 {
  color: var(--dl-color-gray-black50);
  margin-top: var(--dl-space-space-twounits);
}
.products-text20 {
  color: var(--dl-color-gray-black50);
  margin-top: 12px;
}
.products-links-container {
  display: flex;
  grid-gap: var(--dl-space-space-threeunits);
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.products-container4 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.products-text21 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.products-text22 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.products-text23 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.products-text24 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.products-text25 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.products-text26 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.products-text29 {
  color: var(--dl-color-gray-black50);
}
.products-container5 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.products-text30 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.products-text31 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.products-text32 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.products-text33 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.products-text34 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.products-container6 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.products-text35 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.products-text36 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
@media(max-width: 991px) {
  .products-container1 {
    display: none;
  }
  .products-left {
    display: none;
  }
  .products-right {
    display: none;
  }
  .products-icons {
    display: none;
  }
  .products-burger-menu {
    display: flex;
  }
  .products-icon06 {
    width: 24px;
    height: 24px;
    display: flex;
  }
  .products-logo-center1 {
    margin-left: 0px;
  }
  .products-gallery {
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
  }
  .products-footer1 {
    flex-direction: column-reverse;
  }
  .card-text {
    font-size: 18px !important;
    padding-left: 16px !important;
    font-weight: 500 !important;
  }
  .products-links-container {
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .products-text13{
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .products-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .products-gallery {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .products-footer1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .products-links-container {
    margin-top: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: space-between;
  }
}
@media(max-width: 479px) {
  .products-header {
    padding: var(--dl-space-space-unit);
  }
  .products-mobile-menu {
    padding: 16px;
  }
  .products-gallery {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .products-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .products-links-container {
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }
  .products-container4 {
    margin-right: var(--dl-space-space-unit);
  }
  .products-container5 {
    margin-right: var(--dl-space-space-unit);
  }
}
