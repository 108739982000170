.category-card-category-card {
  flex: 0 0 auto;
  cursor: pointer;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
}
.category-card-category-card:hover {
  color: var(--dl-color-pallet-yellow);
  transform: scale(1.2);
}

.category-card-image {
  width: 170px;
  height: 170px;
  object-fit: cover;
  margin-bottom: var(--dl-space-space-unit);
  object-position: bottom;
}
.category-card-text {
  line-height: 16px;
  font-size: 17px;
}
@media(max-width: 767px) {
  .category-card-category-card {
    width: 48%;
  }
  .category-card-image {
    width: 100%;
    height: 250px;
  }
  .category-card-text {
  margin-bottom: 0.85rem;
  }
}
@media(max-width: 479px) {
  .category-card-category-card {
    width: 100%;
  }
  .category-card-image {
    width: 100%;
    height: 250px;
  }
}
