.gallery-card1-gallery-card {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition-duration: 170ms;
}
.gallery-card1-gallery-card:hover{
  /* box-shadow: 0 0 10px 0 rgba(0,0,0,0.2); */
  transform: scale(1.04);

}
.gallery-card1-image {
  width: 100%;
  height: 100%;
  height: 180px;
  object-fit: cover;
}
.gallery-card1-text {
  align-self: flex-start;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}
.gallery-card1-text1 {
  color: var(--dl-color-gray-500);
  align-self: flex-start;
}






@media(max-width: 767px) {
  .gallery-card1-gallery-card {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .gallery-card1-image {
    height: 10rem;
  }
}
