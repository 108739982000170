.applications-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.applications-navbar {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 1000;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-pallet-green);
}
.applications-header {
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
.applications-navbar1 {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
}
.applications-container01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.applications-image {
  width: 24px;
  height: 24px;
  z-index: 100;
  margin-right: -32px;
}
.applications-middle {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  flex-shrink: 1;
  border-color: transparent;
  justify-content: center;
}
.applications-left {
  display: flex;
  grid-gap: 45px;
  position: relative;
  align-items: flex-start;
}
.applications-text {
  text-decoration: none;
}
.applications-text01 {
  text-decoration: none;
}
.applications-text02 {
  text-decoration: none;
}
.applications-logo-center {
  letter-spacing: 0.4em;
  text-decoration: none;
}
.applications-right {
  display: flex;
  grid-gap: 45px;
  position: relative;
  align-items: flex-start;
}
.applications-text05 {
  text-decoration: none;
}
.applications-icons {
  display: flex;
}
.applications-link {
  display: contents;
}
.applications-icon {
  width: 24px;
  height: 24px;
  margin-right: 24px;
  text-decoration: none;
}
.applications-link1 {
  display: contents;
}
.applications-icon02 {
  width: 24px;
  height: 24px;
  margin-right: 24px;
  text-decoration: none;
}
.applications-icon04 {
  width: 24px;
  height: 24px;
}
.applications-burger-menu {
  display: none;
}
.applications-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  display: none;
}
.applications-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  padding: 32px;
  z-index: 100;
  position: absolute;
  transform: translateX(100%);
  transition: 0.3s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.applications-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.applications-container02 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.applications-logo-center1 {
  color: var(--dl-color-gray-black);
  height: auto;
  font-size: 24px;
  font-family: Jost;
  font-weight: 500;
  line-height: 20px;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
  letter-spacing: 0.4em;
  text-decoration: none;
}
.applications-close-mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.applications-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.applications-middle1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  flex-shrink: 1;
  border-color: transparent;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
}
.applications-text07 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.applications-text08 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.applications-text09 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.applications-text10 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.applications-text11 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.applications-text12 {
  color: rgba(0, 0, 0, 1);
  height: auto;
  font-size: 13px;
  text-align: left;
  font-family: Jost;
  font-weight: 500;
  text-decoration: none;
}
.applications-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.applications-icon12 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.applications-icon14 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.applications-container03 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.applications-text13 {
  margin-top: var(--dl-space-space-fiveunits);
  padding-top: var(--dl-space-space-unit);
}
.applications-gallery {
  width: 100%;
  height: 1000px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  /* padding-left: var(--dl-space-space-threeunits); */
  /* padding-right: var(--dl-space-space-threeunits); */
  padding-bottom: var(--dl-space-space-twounits);
}
.applications-container04 {
  flex: 3;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.applications-container05 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.applications-container06 {
  flex: 1;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.applications-container07 {
  flex: 1;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.applications-container08 {
  flex: 1;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
}
.applications-container09 {
  flex: 2;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.applications-container10 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-700);
  border-width: 1px;
  flex-direction: column;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
@media screen and (max-width: 767px) {
  .applications-container03 {
    width: 100vw;
  }
  .applications-container04 {
    width: 100vw;
  }
  .applications-container05 {
    width: 100vw;
  }
  .applications-container06 {
    width: 100vw;
  }
  .applications-container07 {
    width: 100vw;
  }
  .applications-container08 {
    width: 100vw;
  }
  .applications-container09 {
    min-width: 100vw;
  }
  .applications-container10 {
    width: 100vw;
  }
  .applications-container11{
    width: 100vw;
  }
  .applications-container12{
    width: 100vw;
  }
  .applications-container13{
    width: 100vw;
  }
  .applications-container14{
    width: 100vw;
  }
  .applications-container15{
    width: 100vw;
  }
  .applications-container16{
    min-width: 100vw;
  }
  .applications-container17{
    width: 100vw;
  }
  .applications-container18{
    width: 100vw;
  }
  .applications-container19{
    width: 100vw;
  }
  .applications-container20{
    width: 100vw;
  }
  .applications-container21{
    width: 100vw;
  }
  .applications-container22{
    width: 100vw;
  }
  .applications-container23{
    min-width: 100vw;
  }
  .applications-container24{
    width: 100vw;
  }
  .applications-container25{
    width: 100vw;
  }
  .applications-container26{
    width: 100vw;
  }
  .applications-container27{
    width: 100vw;
  }
  .applications-container28{
    width: 100vw;
  }
  .applications-container29{
    width: 100vw;
  }
  .applications-container30{
    min-width: 100vw;
  }
  .applications-container31{
    width: 100vw;
  }
  .applications-container32{
    width: 100vw;
  }
  .applications-container33{
    width: 100vw;
  }
  .applications-container34{
    width: 100vw;
  }
  .applications-container35{
    width: 100vw;
  }
  .applications-container36{
    min-width: 100vw;
  }
  .applications-container37{
    min-width: 100vw;
  }
}
.applications-text14 {
  margin-top: var(--dl-space-space-twounits);
  padding-top: var(--dl-space-space-unit);
}
.applications-gallery1 {
  width: 100%;
  height: 1000px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
}
.applications-container11 {
  flex: 3;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.applications-container12 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.applications-container13 {
  flex: 1;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.applications-container14 {
  flex: 1;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.applications-container15 {
  flex: 1;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
}
.applications-container16 {
  flex: 2;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.applications-container17 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-700);
  border-width: 1px;
  flex-direction: column;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.applications-text15 {
  margin-top: var(--dl-space-space-twounits);
  padding-top: var(--dl-space-space-unit);
}
.applications-gallery2 {
  width: 100%;
  height: 1000px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
}
.applications-container18 {
  flex: 3;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.applications-container19 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.applications-container20 {
  flex: 1;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.applications-container21 {
  flex: 1;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.applications-container22 {
  flex: 1;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
}
.applications-container23 {
  flex: 2;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.applications-container24 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-700);
  border-width: 1px;
  flex-direction: column;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.applications-text16 {
  margin-top: var(--dl-space-space-twounits);
  padding-top: var(--dl-space-space-unit);
}
.applications-gallery3 {
  width: 100%;
  height: 1000px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
}
.applications-container25 {
  flex: 3;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.applications-container26 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.applications-container27 {
  flex: 1;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.applications-container28 {
  flex: 1;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.applications-container29 {
  flex: 1;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
}
.applications-container30 {
  flex: 2;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.applications-container31 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-700);
  border-width: 1px;
  flex-direction: column;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.applications-text17 {
  margin-top: var(--dl-space-space-twounits);
  padding-top: var(--dl-space-space-unit);
}
.applications-gallery4 {
  width: 100%;
  height: 1000px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
}
.applications-container32 {
  flex: 3;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.applications-container33 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.applications-container34 {
  flex: 1;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.applications-container35 {
  flex: 1;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.applications-container36 {
  flex: 1;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  flex-direction: column;
}
.applications-container37 {
  flex: 2;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.applications-footer {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-950);
  border-width: 1px;
  flex-direction: column;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.applications-footer1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-sixunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-sixunits);
  justify-content: space-between;
}
.applications-container38 {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
}
.applications-text18 {
  margin-bottom: var(--dl-space-space-halfunit);
}
.applications-text19 {
  color: var(--dl-color-gray-black50);
  margin-top: var(--dl-space-space-oneandhalfunits);
}
.applications-text23 {
  color: var(--dl-color-gray-black50);
  margin-top: var(--dl-space-space-twounits);
}
.applications-text24 {
  color: var(--dl-color-gray-black50);
  margin-top: 12px;
}
.applications-links-container {
  display: flex;
  grid-gap: var(--dl-space-space-threeunits);
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.applications-container39 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.applications-text25 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.applications-text26 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.applications-text27 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.applications-text28 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.applications-text29 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.applications-text30 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.applications-text33 {
  color: var(--dl-color-gray-black50);
}
.applications-container40 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: flex-start;
}
.applications-text34 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.applications-text35 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.applications-text36 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.applications-text37 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.applications-text38 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
.applications-container41 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.applications-text39 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 44px;
}
.applications-text40 {
  color: var(--dl-color-gray-black50);
  margin-bottom: var(--dl-space-space-unit);
}
@media(max-width: 991px) {
  .applications-container01 {
    display: none;
  }
  .applications-left {
    display: none;
  }
  .applications-right {
    display: none;
  }
  .applications-icons {
    display: none;
  }
  .applications-burger-menu {
    display: flex;
  }
  .applications-icon06 {
    width: 24px;
    height: 24px;
    display: flex;
  }
  .applications-logo-center1 {
    margin-left: 0px;
  }
  .applications-container09 {
    width: 100%;
  }
  .applications-container16 {
    width: 100%;
  }
  .applications-container23 {
    width: 100%;
  }
  .applications-container30 {
    width: 100%;
  }
  .applications-container37 {
    width: 100%;
  }
  .applications-footer1 {
    flex-direction: column-reverse;
  }
  .applications-links-container {
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .applications-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .applications-gallery {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .applications-container05 {
    flex-direction: column;
  }
  .applications-gallery1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .applications-container12 {
    flex-direction: column;
  }
  .applications-gallery2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .applications-container19 {
    flex-direction: column;
  }
  .applications-gallery3 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .applications-container26 {
    flex-direction: column;
  }
  .applications-gallery4 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .applications-container33 {
    flex-direction: column;
  }
  .applications-footer1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .applications-links-container {
    margin-top: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: space-between;
  }
}
@media(max-width: 479px) {
  .applications-header {
    padding: var(--dl-space-space-unit);
  }
  .applications-mobile-menu {
    padding: 16px;
  }
  .applications-gallery {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .applications-container04 {
    width: auto;
  }
  .applications-gallery1 {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .applications-container11 {
    width: auto;
  }
  .applications-gallery2 {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .applications-container18 {
    width: auto;
  }
  .applications-gallery3 {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .applications-container25 {
    width: auto;
  }
  .applications-gallery4 {
    padding: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .applications-container32 {
    width: auto;
  }
  .applications-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .applications-links-container {
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }
  .applications-container39 {
    margin-right: var(--dl-space-space-unit);
  }
  .applications-container40 {
    margin-right: var(--dl-space-space-unit);
  }
}
